import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Typography } from "@mui/material";

interface PaymentStatusLabelProps {
  paymentStatus: string;
  isPreAuthorized: boolean;
}

export default function PaymentStatusLabel({
  paymentStatus,
  isPreAuthorized = false,
}: Readonly<PaymentStatusLabelProps>) {
  const label = {
    initialized: {
      text: "Open",
      color: "wildTide.500",
      bgColor: "wildTide.50",
    },
    preAuthorized: {
      text: "On Hold",
      color: "#FFB020",
      bgColor: "warningOrange.50",
    },
    cancelled: {
      text: isPreAuthorized ? "Reversed" : "Cancelled",
      color: "purple.500",
      bgColor: "purple.50",
    },
    succeeded: {
      text: "Succeeded",
      color: "secondary.main",
      bgColor: "newEmerald.50",
    },
    failed: {
      text: "Failed",
      color: "errorRed.500",
      bgColor: "errorRed.50",
    },
  }[paymentStatus];

  if (!label) return null;

  return (
    <Grid container item xs="auto" alignItems="center" bgcolor={label.bgColor} px={0.5} mr={1} borderRadius={0.5}>
      <Typography variant="caption" sx={{ color: label.color }}>
        {isPreAuthorized && "Holding Fee - "}
        {label.text}
      </Typography>

      {paymentStatus === "succeeded" && <CheckIcon sx={{ color: "newEmerald.500", fontSize: 16 }} />}
      {paymentStatus === "failed" && <ClearIcon sx={{ color: "errorRed.500", fontSize: 16 }} />}
    </Grid>
  );
}
